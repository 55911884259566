
body {

  font-family: Arial,
    sans-serif;
margin: 0;
  padding: 0;
   background-color: #000020;
  
 
}

  
h1{
  font-size: 36px;
  margin-top: 0;
  color: #0a0a0a;
  font-weight: bold !important;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial,
    sans-serif;
  /* font: normal normal normal 30px/34px Helvetica Neue; */
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}
p {
  margin: 0;
    text-align: left;
   
  
  font-family: Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 5px;
  padding-left: 40px;
  padding-right: 40px;
}
.mar{
  color: #FFFFFF;
  font-weight: bold;
}
/* Navbar */
.navbar {
  background-color:black;
  padding: 0.1rem;
  box-shadow: 0px 2px 2px #8B4513;
  position: fixed;
  width: 100%;
  z-index: 100;

  }
 /* rgba(0, 0, 0, 0.1) */
  .navbar img{
    max-width: 15%;
    height: auto;
  }
@media (max-width: 768px) {
  .navbar {
  
    padding-top: 20px;
      padding-bottom: 20px;
  }
   .navbar img{
    max-width: 28%;
    height: auto;
  }
}
@media (max-width: 540px) {
   .navbar img{
    max-width: 50%;
    height: auto;
  }
}
.container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
  
    }
  

@media (min-width: 320px) {
  .navbar-logo {
  max-width: 68%;
    padding-right: 0px;
      padding-left: -10px;
  }
}
@media (min-width: 280px) {
  .navbar-logo {
    max-width: 68%;
    padding-right: 0px;
    padding-left: -10px;
  }
}
.navbar-toggle {
  border: none;
  background-color: transparent;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.navbar-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar-item {
  margin: 0.5rem 0;
}

.navbar-link {
  font-size: 1.2rem;
  font-weight: normal;
  color: white;
  text-decoration: none;
   
}
.navbar-link :hover{
  box-shadow: 0px 2px 2px #8B4513;
}


section{
padding-top: 50px;
padding-bottom: 50px;
  margin:0% auto;

 padding-left: 40px;
 padding-right: 40px;
   background-color: #000020;
    
  color:black;
  /* box-shadow: 0 0 5px 2px rgba(0,0,0,0.15);  */
 
}







ul {
  list-style: none;
  margin-bottom: 20px;
  padding: 0;
}

li {
  margin-bottom: 1rem;
}



ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 1rem;
}



blockquote {
  font-size: 1.2rem;
  font-style: italic;
  color:#FFFFFF;
}


 
 

 h1 {
   font-size: 36px;
   margin-bottom: 40px;
 }

.custom-btn {
  background-color: #8B4513; /* Brown color */
  border-color: #8B4513; /* Brown color */
}

 .text{
  position: absolute;
  top: 50%;
  left: 35.5%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: #FFFFFF;
padding-top: 10px;
  padding: 20px;
  border-radius: 10px;
 }
 .text1{
  text-align: left;
  padding-left: 0%;
  color:white !important;
  font-size: 1.5em;
  
 }
 .box1{
  background-color: #000020;
  padding-top:100px;
  padding-bottom:100px;
 }
.container1{
  display: flex;
  justify-content: space-between;
 flex-wrap: wrap;
 margin-bottom: 40px;


}

@media only screen and (max-width:411px) {

 #box1 {
   
   padding-left: 0px;
    padding-right: 0px;
 }
 

}


.box{
  width: calc(33.33% - 20px);
  margin-bottom:10px;
  box-sizing: border-box;
  padding: 15px;
  color:black;
  border: 1px solid #ddd ;
  background-color:#F8F8F8;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0);
  border-radius: 3px;
  text-align: left;
  
                           
}
.box6{
  width: calc(33.33% - 20px);
  margin-bottom:10px;
 
  box-sizing: border-box;
  /* padding: 15px; */
  color:black;
  border: 1px solid #ddd ;
  background-color:#F8F8F8;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0);
  border-radius: 3px;
  text-align: left;
  border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
@media only screen and (max-width:767px) {
  .box{
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width:767px) {
  .box {
    width: 100%;
  }
}




.box6 {
  width: 31%;
  margin-bottom: 2rem;
}

.box6 img {
  width: 100%;
  
}

.box6 p {
  font-size: 0.8rem;
  color:black;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .box6 {
    width: 45%;
  }
}

@media (max-width: 576px) {
  .box6 {
    width: 100%;
  }
}
.testimonials {
  /* background-color: #5E7A8A; */
  background-color:#f2f2ff;
  padding: 60px 0;
  text-align: center;
  color: black;
}


.testimonial-card {
  width: 50%;
  margin: 0 auto 40px;
  padding: 40px;
  /* background-color: #4E6A7C; */
  background-color: #f2f2ff;
   box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); 
  text-align: left;
  color:black;
}
@media(max-width:510px) {
  .testimonial-card{
    width: 80%;
  }
}
.testimonial-card blockquote {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 20px;
  color: black;
}

.testimonial-card .author {
  font-size: 16px;
  font-weight: bold;
  color:black;
}

@media(max-width:510px) {
 img{ width: 100%;
  height: auto;
 }
 #foot {
   font-size: 13px;
   margin-top: 10px;
  
   
 }
}
@media(max-width:768px) {
  img {
    width: 100%;
    height: auto;
  }

  #foot {
    font-size: 13px;
    margin-top: 10px;
    padding-left: -10px;

  }
}




.footer {
  /* background-color: #033E6B; */
  background-color: #F1F1F1;
  color: #FFFFFF;
}
#foot{
  padding-left: 0px;
    padding-right: 0px;
    font-size: 1.1rem;
}


 
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  /* Two columns that adjust to screen size */
  grid-gap: 20px;
  /* Add some space between the columns */
}

.column {
  background-color: 'rgba(0, 0, 0, 0.2)';
  padding: 20px;
}
 

/* Media query to make columns stack on small screens */
@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: 1fr;
    
  }
  
}



#one img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
#two img {

  width: 100%;
    height: 60vh;
    object-fit: cover;
}
@media screen and (max-width: 600px) {
  #two img {
  
      width: 100%;
      height: 50vh;
      object-fit: cover;
    }

}
@media screen and (max-width: 375px) {
  #two img {

    width: 100%;
    height: 40vh;
    object-fit: cover;
  }

}
#hi {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 5px;
}

#hi h3 {
  color: #fff;
}

#hi p {
  color: #fff;
}




.my-row {
   background-color: #FFF;  
  /* background-color:#5E7A8A ; */
  padding-top: 50px;
  padding-bottom: 50px;
  margin-right: 0px;
    margin-left: 0px;
  
}
.my-row ol{
 
  padding-left: 0px;

}
@media (max-width: 950px) {
  .my-row p{
    
    padding-left: 80px;
    padding-right: 80px;
  }
    .my-row ol {
  
      padding-left: 120px;
      
    }

}
@media (max-width: 540px) {
  .my-row p{
  
    padding-left: 50px;
    padding-right: 50px;
  }
     .my-row ol {
  
       padding-left: 100px;
  
     }

}

@media (min-width: 360px) {
  .my-row {
    
      padding-left: 0px;
      padding-right: 0px;
    }
 .my-row ol {

   padding-left: 100px;

 }
}

@media (max-width: 768px) {
  .my-row {
      
      padding-left: 0px;
      padding-right: 0px;
    }
        .my-row ol {
    
          padding-left: 100px;
    
        }
}
@media (max-width: 375px) {
  .my-row {
     
      padding-left: 0px;
      padding-right: 0px;
    }
        .my-row ol {
    
          padding-left: 100px;
    
        }
}
@media (min-width: 411px) {
  .my-row {
  
    padding-left: 0px;
      padding-right: 0px;
  }
.my-row ol {

  padding-left: 100px;

}
 
}
@media (min-width: 280px) {
  .my-row {
  
    padding-left: 0px;
    padding-right: 0px;
  }

  .my-row ol {

    padding-left: 80px;

  }

}
/* Oferta */
.my-row2{
  padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 0px;
    margin-left: 0px;
}
.my-row2 li{
  padding-left: 55px;
}
@media (min-width: 375px) {
  .my-row2 {

    padding-left: 0px;
    padding-right: 0px;
  }

  .my-row2 ol {

    padding-left: 0px;

  }

  #ofer {
    padding-left: 0px;
    padding-right: 0px;
  }

}
@media (min-width: 360px) {
  .my-row2 {

    padding-left: 0px;
    padding-right: 0px;
  }

  .my-row2 ol {

    padding-left: 0px;

  }

  #ofer {
    padding-left: 0px;
    padding-right: 0px;
  }

}
@media (min-width: 280px) {
  .my-row2 {

    padding-left: 0px;
    padding-right: 0px;
  }

  .my-row2 ol {

    padding-left: 0px;

  }
  #ofer{
padding-left:0px;
padding-right:0px;
  }

}


.hero-image1 {
  /* background-image: url("./pages/images/a.jpg"); */
  height: 75vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  opacity: 0.7;
 
} 
.hero-image12 {
  /* background-image: url("./pages/images/foto 4.jpg"); */
  height: 75vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  opacity: 0.7;

}
.hero-texto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 1;
}
.hero-texto h1{
  color: #fff;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}


     .hero-slider {
       position: relative;
       width: 100%;
       height: 600px;
       overflow: hidden;
     }
  
     .hero-image {
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       opacity: 0;
       transition: opacity 0.3s ease-in-out;
     }
  
     .hero-image.active {
       opacity: 1;
     }
  
     .hero-container {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       text-align: center;
       
     }
  
     .hero-text {
       font-size: 1.5rem;
      
       margin-bottom: 1rem;
       color: #fff;
      background-color: black;
      opacity: 0.7;
      padding: 20px;
      border-radius: 3px;
     }
  
     
  
     .slider-prev {
       left: 2rem;
     }
  
     .slider-next {
       right: 2rem;
     }
 @media(min-width:1280px) {
   .carousel-item {
     height: 500px;
   }
     .carousel-item img {
       max-height: 100%;
       object-fit: cover;
       max-width: 100%;
     }

 }
         
 @media(min-width:1280px) {
          #page {
             height: 700px;
           }
    
           #page img {
             max-height: 100%;
             object-fit: cover;
             max-width: 100%;
           }}


 
 
 @media(max-width:768px) {
   #page {
     margin-top: 80px;
     
   }

 
 }
    

 @media(max-width:375px) {
   #page {
    margin-top: 90px;
   }

   
 }


.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  border-radius: 3px;
}

.hero-text h6 {
  font-size: 1.5em;
  margin: 0;
}

@media (max-width: 767px) {
  .hero-text h6 {
    font-size: 2em;
  }
}

@media (max-width: 540px) {
  .hero-text h6 {
    font-size: 0.9em;
  }
}
@media (max-width: 411px) {
  .hero-text h6 {
    font-size: 0.8em;
  }
}
@media (max-width: 375px) {
  .hero-text h6 {
    font-size: 0.7em;
  }
    
    }

        @media (max-width: 280px) {
          .hero-text h6 {
            font-size: 0.53em;
          }}


.btn{
   background-color:#F78805;
  color:white;
    font-size: 24px;
  font-weight: 700;
} 

       

.testimonial{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .testimonial {
    width: 30%;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
  }

  .testimonial img {
    width: 50%;
    margin-bottom: 10px;
  }

  .testimonial p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .testimonial cite {
    font-style: italic;
    font-size: 14px;
  }

  .btn-warning {
  background-color: #ff9900;
  color:black;
  border-color: #ff9900;
}
@media (max-width: 540px) {
  .btn {
    padding: 8px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .btn {
    padding: 10px 15px;
    font-size: 20px;
  }
}
@media (max-width: 411px) {
  .btn {
    padding: 5px;
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .btn {
    padding: 5px;
    font-size: 13px;
  }
}
@media (max-width: 280px) {
  .btn {
    padding: 5px;
    font-size: 10px;
  }
}

  .house-carousel-image {
   object-fit: cover; 
  color:lightgray;
  height: 480px;
}
.house1{
 

  padding-top: 150 px;
  padding-left: 200px;
  padding-right: 200px;
background-color: #000020;
color:lightgray;


}
@media (max-width: 768px) {
  .house1 {
   padding: 0%;
   padding-bottom: 50px;
   padding-top: 40px;
  }
.h2h{
  padding-top: 50px;
  color: black;
}

}
.hh{
margin-bottom: 0px;
      font-size: 1rem;
      padding-top: 100px;
      color:lightgray;
}
.detail {
  background-color: #f2f2ff;
  color:black;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .carsoul img {
  max-width: 50%;
  }
}

.house-carousel-image {
  width: 50%;
  height: auto;
}
#cardc {
  
  height: 640px;


}

/* contactform */
.pt-100 {
  padding-top: 150px;
}

.text-success {
  color: #28a745;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.text-white {
  color: white;
}

.bg-white {
  background-color: white;
}

.border {
  border: 1px solid #dee2e6;
}

.rounded {
  border-radius: 0.25rem;
}

.fa {
  margin-right: 5px;
}
@media (max-width: 540px) {
  #info {
    margin-top:24px;
  }
}

/* Onas */

.containerA {
  display: flex;
    flex-wrap: wrap;
    padding-top: 100px;
background-color: #000020;
color:#ddd
}
@media only screen and (max-width: 768px) {
  .containerA {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.containerA p{
  padding-top: 10px;
}
.responsive-section {
  position: relative;
  /* added to contain the absolute positioned overlay */
}

.responsive-image {
  width: 100%;
  height: 794px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  /* changed from 80% to 50% for better centering */
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(244, 140, 4);
  padding: 10px;
  margin: 10px;
  text-align: center;
  /* added for better text alignment */
}

/* added media query for mobile devices */
@media only screen and (max-width: 768px) {
  .responsive-image {
    width: 100vw;
    /* use viewport width for full-width image on mobile */
    height: 50vh;
    /* adjust height to maintain aspect ratio */
  }

  .text-overlay {
    font-size: 16px;
    /* adjust font size for better readability on mobile */
    margin-top: 30px;
  
   
  }
  .pt{
    padding-left: 10px;
    padding-right: 10px;
  }
}

#ab{
padding-right: 0px;
  padding-left: 0px;

    padding-bottom: 0px;
    padding-top: 0px;
  
  
}
.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .img-fluid {
    width: 100vw;
  
    height: 50vh;
  
  }
}
.float-right {
  float: right;
  margin-left: 15px;
 
  margin-bottom: 15px;
}
#i {
  height: 600px;
    object-fit: cover;
}
.h2 {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.ho {
  color:#0a0a0a;
  font-weight: normal;
  text-align: center;
  padding-bottom:10px;
  padding-top: 0px;


}
@media only screen and (max-width: 768px) {
  .ho {
   font-size: 24px;

  }
}


@media only screen and (max-width: 768px) {
  #image {
    width:60%;
    margin-left:30px;

  }
}
@media only screen and (max-width: 540px) {
  #image {
  width: 100%;
    margin-left: 0px;
    

  }
}
